@charset "UTF-8";

/*
MAP STYLES
*/

/***********************************************************************
Context Menu
***********************************************************************/
.padding-top-header {
  padding-top: 80px;
}

.contextMenu {
  position: absolute;
  width: auto;
  z-index: 99999;
  border: solid 1px #ccc;
  background: #eee;
  padding: 0;
  margin: 0;
  display: none;
}

.contextMenu li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.contextMenu a {
  color: #333;
  text-decoration: none;
  display: inline-block;
  line-height: 20px;
  height: 20px;
  background: no-repeat 6px center;
  outline: none;
  padding: 1px 5px 1px 28px;
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .contextMenu a {
    font-size: 14px;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .contextMenu a {
    font-size: 12px;
  }
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {
  .contextMenu a {
    font-size: 12px;
  }
}

.contextMenu li.hover a {
  color: #fff;
  background-color: #39f;
}

.contextMenu li.disabled a {
  color: #aaa;
  cursor: default;
}

.contextMenu li.checked a {
  background-image: url(http://dl.dropbox.com/u/20165443/jsFiddle/images/ICON_OK.png);
}

.contextMenu li.hover.disabled a {
  background-color: transparent;
}

.contextMenu li.separator {
  border-top: solid 1px #ccc;
}

.content {
  width: 100px;
  height: 100px;
  overflow: scroll;
  background-color: yellow;
}

@font-face {
  font-family: 'DSDIGI';
  src: url('../fonts/DSDIGI.eot');
  src: url('../fonts/DSDIGI.woff') format('woff'),
  url('../fonts/DSDIGI.ttf') format('truetype')
}

@keyframes blink {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.blink {
  animation: blink 600ms infinite;
  -webkit-animation: blink 600ms infinite;
}

/* Chrome & Safari */

@keyframes blink-kf {
  0% {
    opacity: 1;
  }

  15% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  65% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.svg-blink {
  animation: blink-kf 1s steps(14) infinite;
  -webkit-animation: blink-kf 1s steps(14) infinite;
}

/**
 * Typography
 */
@font-face {
  font-family: 'nuovo_fontregular';
  src: url('../fonts/gemss-icons-webfont.eot');
  src: url('../fonts/gemss-icons-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/gemss-icons-webfont.woff') format('woff'),
  url('../fonts/gemss-icons-webfont.ttf') format('truetype'),
  url('../fonts/gemss-icons-webfont.svg#nuovo_fontregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

.gemss-icon {
  font-family: "nuovo_fontregular";
}

.appname > span {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  color: #fff;
}

.appname {
  width: 180px;
  line-height: 45px;
  text-align: center;
}

.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* Everything but the jumbotron gets side spacing for mobile first views */
.header,
.marketing,
.footer {
  padding-left: 15px;
  padding-right: 15px;
}

/* Custom page header */
.header {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 10px;
}

/* Make the masthead heading the same height as the navigation */
.header h3 {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 40px;
  padding-bottom: 19px;
}

/* Custom page footer */
.footer {
  padding-top: 19px;
  color: #777;
  border-top: 1px solid #e5e5e5;
}

.container-narrow > hr {
  margin: 30px 0;
}

/* Main marketing message and sign up button */
.jumbotron {
  text-align: center;
  border-bottom: 1px solid #e5e5e5;
}

.jumbotron .btn {
  font-size: 21px;
  padding: 14px 24px;
}

/* Supporting marketing content */
.marketing {
  margin: 40px 0;
}

.marketing p + h4 {
  margin-top: 28px;
}

.scroll {
  height: 250px;
  overflow: scroll;
}

/* Responsive: Portrait tablets and up */
@media screen and (min-width: 768px) {
  .container {
    max-width: 730px;
  }

  .header,
  .marketing,
  .footer {
    padding-left: 0;
    padding-right: 0;
  }

  .header {
    margin-bottom: 30px;
  }

  .jumbotron {
    border-bottom: 0;
  }
}

.show-grid {
  margin-bottom: 5px;
}

body > .inner {
  display: none;
}

body.pg-loaded > .inner {
  display: block;
}

.customer-brand {
  border-radius: 100%;
  background-color: #fff;
  margin-top: -5px;
  text-align: center;
  width: 50px;
  height: 50px;
  overflow: hidden;
}

.customer-brand img {
  margin: 0 auto;
}

.menu-title {
  padding: 10px 20px;
  color: #aaa;
}

.sk-folding-cube {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1);
}

.sk-folding-cube .sk-cube::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(6, 73, 223);
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  transform-origin: 100% 100%;
}

.sk-folding-cube .sk-cube2 {
  transform: scale(1.1) rotateZ(90deg);
}

.sk-folding-cube .sk-cube3 {
  transform: scale(1.1) rotateZ(180deg);
}

.sk-folding-cube .sk-cube4 {
  transform: scale(1.1) rotateZ(270deg);
}

.sk-folding-cube .sk-cube2::before {
  animation-delay: 0.3s;
}

.sk-folding-cube .sk-cube3::before {
  animation-delay: 0.6s;
}

.sk-folding-cube .sk-cube4::before {
  animation-delay: 0.9s;
}

@keyframes sk-foldCubeAngle {
  0%,
  10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }

  90%,
  100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

/*
TABLES STYLES
*/

.table-striped > tbody > tr:nth-of-type(2n+1) {
  background-color: rgba(250, 250, 250, 0.3);
}

.table-striped > tbody > tr:nth-of-type(2n) {
  background-color: rgba(210, 210, 210, 0.3);
}

.table-hover > tbody > tr:hover {
  background-color: rgba(230, 230, 230, 0.6);
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  vertical-align: middle;
}

/* ERROR MESSAGES */
.alert.alert-danger {
  position: relative;
  margin-top: 7px;
}

.alert.alert-danger .arrow {
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  border: 1px solid #ebccd1;
  border-bottom: none;
  border-right: none;
  position: absolute;
  top: -6px;
  left: 20px;
  z-index: 100;
}

.img-box {
  width: 64px;
  height: 64px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid #d0d0c0;
}

caption {
  line-height: 30px;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.3);
  text-transform: uppercase;
}

h1 span.logo {
  margin-right: 30px;
}

.infoCustomer .col-sm-3 {
  clear: both;
}

.table.table-hover tr {
  cursor: pointer;
}

.searchText {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ccc;
  width: 0;
  transition: all ease-in-out 0.5s;
}

.searchText.opened {
  width: 300px;
  transition: all ease-in-out 0.5s;
}

.searchResults {
  position: absolute;
  right: 30px;
  color: #a9a9a9;
  opacity: 0;
  transition: all ease-in-out 0.5s;
}

.searchResults.opened {
  opacity: 1;
  transition: all ease-in-out 0.5s;
  transition-delay: 0.5s;
}

.searchField {
  position: relative;
  display: inline-block;
  margin-right: 30px;
}

.glyphicon {
  margin-right: 6px;
}

button .glyphicon {
  margin-right: 0;
}

.searchField .glyphicon {
  font-size: 1.3em;
  vertical-align: sub;
  cursor: pointer;
}

.plantsmanagement .label {
  font-size: 90%;
}

/**
 * Grafica di controllo accessi
 */

@font-face {
  font-family: 'latobold';
  src: url('/fonts/lato-bold-webfont.eot');
  src: url('/fonts/lato-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/lato-bold-webfont.woff2') format('woff2'),
  url('/fonts/lato-bold-webfont.woff') format('woff'),
  url('/fonts/lato-bold-webfont.ttf') format('truetype'),
  url('/fonts/lato-bold-webfont.svg#latobold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'latolight';
  src: url('/fonts/lato-light-webfont.eot');
  src: url('/fonts/lato-light-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/lato-light-webfont.woff2') format('woff2'),
  url('/fonts/lato-light-webfont.woff') format('woff'),
  url('/fonts/lato-light-webfont.ttf') format('truetype'),
  url('/fonts/lato-light-webfont.svg#latolight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'latoregular';
  src: url('/fonts/lato-regular-webfont.eot');
  src: url('/fonts/lato-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/lato-regular-webfont.woff2') format('woff2'),
  url('/fonts/lato-regular-webfont.woff') format('woff'),
  url('/fonts/lato-regular-webfont.ttf') format('truetype'),
  url('/fonts/lato-regular-webfont.svg#latoregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

body {
  background-color: #ebe5d9;
  font-family: "latoregular", monospace;
}

h1, h2 {
  font-family: "latolight";
  text-transform: uppercase;
  color: #334556;
}

h3.panel-title {
    font-family: "latobold", monospace;
  text-transform: uppercase;
  color: #fff;
}

.panel-default > .panel-heading {
  background-color: #334556;
  padding: 20px 15px;
    font-family: "latoregular", monospace;
  text-transform: uppercase;
  color: #fff;
}

ul {
  margin: 0;
  padding: 0;
}

.loaderScreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  background-size: 75px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../../img/ajax_loader.gif);
  background-color: rgba(255, 255, 255, 0.8);
  display: none;
}

.errorMessage {
  margin-top: 5px;
}

.new-db-name {
  padding-top: 15px;
}

.select-orari .alertOrario {
  display: none;
}

.select-orari input {
  text-align: center;
}

.select-orari span {
  display: none;
  position: absolute;
  right: 0;
  top: 10px;
  z-index: 10;
}

.alert {
  margin-bottom: 0;
}

span.glyphicon-minus {
  display: none;
}

.separator {
  width: 100px;
  height: 0;
  border-bottom: 2px solid #334556;
  margin: 10px 0 30px;
}

td.separator {
  border-bottom: none;
}

.draggable:hover {
  cursor: move;
}

input[type="file"] {
  margin-bottom: 20px;
}

#file-upload-progress {
  display: none;
}

.infoclient.glyphicon {
  margin-left: 10px;
  font-size: 1.3em;
  vertical-align: sub;
}

.infoclient {
  cursor: pointer;
}

.navbar-brand {
  padding: 8px 15px;
}

.navbar.navbar-default {
  background-color: #334556;
  height: 70px;
}

.navbar-nav > li {
  padding: 0 20px;
}

.navbar-default .navbar-nav > li > a {
  padding: 10px 0;
  color: #fff;
  background-color: transparent;
}

li.dropdown > a {
  border-bottom: 2px solid #334556;
}

li.dropdown li {
  min-width: 250px;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
  color: #fff;
  background-color: transparent;
  border-bottom: 2px solid #fff;
}

.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li > a:hover {
  color: #fff;
  border-bottom: 2px solid #fff;
}

.navbar-default .navbar-nav.navbar-right > li > a {
  color: #aaa;
}

.navbar-default .navbar-nav.navbar-right > li > a:hover,
.navbar-default .navbar-nav.navbar-right > li.active > a {
  color: #fff;
}

#main-menu-collapse {
  margin-top: 15px;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

/* PANELS */

.addButton {
  font-size: 0.7em;
  padding: 2px 5px;
  color: #aaa;
  transition: all ease-in-out 0.3s;
}

.addButton .glyphicon-plus {
  margin-top: -1px;
  margin-left: 5px;
}

.addButton:hover {
  color: #fff;
}

.table-body h3 {
  font-size: 18px;
  font-weight: 700;
  margin: 0 0 10px;
}

li .glyphicon-pencil,
li .glyphicon-remove {
  opacity: 0;
  color: #999;
  transition: all ease-in-out 0.3s;
  margin-right: -10px;
  margin-left: 20px;
}

.glyphicon-pencil:hover,
.glyphicon-remove:hover {
  cursor: pointer;
}

.username .glyphicon-remove {
  color: #e50000;
}

li:hover .glyphicon-pencil,
li:hover .glyphicon-remove {
  opacity: 1;
}

.table-header > ul {
  border-bottom: 2px solid #ccc;
}

.table-header li,
.table-body li > ul > li {
  display: inline-block;
}

li {
  list-style-type: none;
}

.table-body > ul > li,
.terminali-profilo .table-body li.draggable {
  border-bottom: 1px solid #ccc;
}

.table-header ul,
.table-body > ul > li,
.table-body > ul > li li,
.droppable-container li,
.terminali-profilo .table-body li.draggable {
  padding: 8px;
}

.table-body > ul > li ul {
  margin: -8px;
}

.table-body > ul > li.draggable.dragging {
  height: auto !important;
}

.table-body > ul > li:nth-child(odd),
.terminali-profilo .table-body li.draggable:nth-child(odd) {
  background-color: #f9f9f9;
}

.table-body > ul > li:nth-child(even),
.terminali-profilo .table-body li.draggable:nth-child(even) {
  background-color: #fff;
}

#terminali,
.terminali-profilo {
  position: relative;
  z-index: 100;
}

#badge {
  position: relative;
  z-index: 101;
}

#utenti .filters li.refresh,
#badge .filters li.refresh {
  width: 10%;
}

#utenti .filters li.refresh span,
#badge .filters li.refresh span {
  margin-left: 10px;
  font-size: 1.5em;
  vertical-align: middle;
}

#terminali .table-header > ul > li,
#terminali .table-body li > ul > li,
.terminali-profilo .table-header > ul > li,
.terminali-profilo .table-body li > ul > li {
  width: 10%;
}

#terminali .table-header > ul > li:nth-child(2),
#terminali .table-body li > ul > li:nth-child(2),
.terminali-profilo .table-header > ul > li:nth-child(2),
.terminali-profilo .table-body li > ul > li:nth-child(2) {
  width: 60%;
}

#profili .droppable-container,
#profili .terminali-dropped,
#profili .fasce-orarie-dropped,
#profili .badge-dropped,
#utenti .droppable-container,
#utenti .badge-dropped {
  display: none;
}

.droppable-container div > ul {
  padding: 10px 0 30px;
}

#edit-profilo .droppable-container div > ul {
  padding: 10px 10px 30px;
}

#profili .table-body > ul > li,
#utenti .table-body > ul > li {
  cursor: pointer;
}

.terminali-profilo .droppable-container ul.dropping {
  padding: 50px 0 0;
}

/* MODALI */
.modal-content {
  border-radius: 0;
}

.modal-header {
  background-color: #334556;
}

.modal-title {
  color: #fff;
  font-weight: 700;
}

.modal-header .close {
  color: #fff;
  opacity: 0.6;
}

#aggiornamento-firmware .labels,
#aggiornamento-firmware .values,
#settings .labels,
#settings .values,
.modal-content .labels,
.modal-content .values {
  display: inline-block;
}

#aggiornamento-firmware .labels,
#aggiornamento-firmware .values,
#settings .labels,
#settings .values {
  width: 50%;
}

#settings .labels {
  vertical-align: top;
  margin-top: 13px;
}

#settings .settings-field {
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}

.modal-content .labels {
  width: 30%;
  vertical-align: top;
  margin-top: 13px;
}

.modal-content .values {
  width: 70%;
  padding: 5px 0;
}

.modal-content .well .form-control {
  display: inherit;
}

.modal-content .well input[type='number'].form-control,
.modal-content .well select.form-control {
  width: 250px;
}

.options {
  position: relative;
  display: none;
}

#last-events,
#exportEvents,
.submit-buttons button.btn-warning,
.modal-content .submit-buttons button.btn-warning {
  margin-right: 20px;
}

#edit-fascia-oraria ul.orario > li {
  width: 47%;
  text-align: center;
  display: inline-block;
  padding: 10px 0;
  border-bottom: 1px solid #999;
}

#edit-fascia-oraria ul.orario > li:first-child {
  margin-right: 6%;
}

#edit-fascia-oraria .select-orari {
  display: inline-block;
  width: 47%;
  margin-right: 5.5%;
}

#edit-fascia-oraria .values .select-orari:last-child {
  margin-right: 0;
}

#edit-fascia-oraria .select-orari select {
  width: 45%;
  display: inline-block;
}

#edit-fascia-oraria .select-orari .oraInizio,
#edit-fascia-oraria .select-orari .oraFine {
  float: left;
  width: 47%;
}

#edit-fascia-oraria .select-orari .oraFine {
  float: right;
  text-align: right;
}

#edit-fascia-oraria .select-orari select.ore option:first-child,
#edit-fascia-oraria .select-orari select.minuti option:first-child {
  color: #999;
}

#edit-fascia-oraria .select-orari select.form-control {
  padding: 6px 3px;
}

/* BADGE */

.badge-deleted {
  padding: 30px;
  border: 15px solid #d43f3a;
}

.badge-deleted::before {
  content: "BADGE CANCELLATO";
  float: right;
  margin-top: -20px;
  font-size: 2em;
  color: #d43f3a;
  font-weight: bold;
}

#badge .table-header > ul > li:first-child,
#badge .table-body li > ul > li:first-child {
  width: 10%;
}

#badge .table-header > ul.filters > li:first-child {
  width: 20%;
}

#badge .table-header > ul.filters > li:nth-child(2) {
  width: 40%;
}

#badge .table-header > ul.filters > li:nth-child(3) {
  width: 27%;
}

#badge .table-header > ul > li:nth-child(2),
#badge .table-body li > ul > li:nth-child(2) {
  width: 42%;
}

#badge .table-header > ul > li:nth-child(3) {
  width: 35%;
}

#badge .table-body li > ul > li:nth-child(3) {
  width: 30%;
}

.table-body .badge-dropped > ul > li {
  padding: 8px;
}

.table-body .terminali-dropped > ul > li > ul,
.table-body .badge-dropped > ul > li > ul {
  margin: 0;
}

.table-body .terminali-dropped > ul > li li,
.table-body .badge-dropped > ul > li li {
  padding: 0;
  padding-right: 20px;
}

.online ul,
.badge-active ul,
.table-body .badge-dropped .badge-active {
  background-color: rgba(132, 238, 124, 0.2);
}

.offline {
  color: #aaa;
}

.table-body .badge-dropped .badge-active ul {
  background-color: transparent;
}

/* UTENTI */
#utenti .table-header > ul > li:first-child,
#utenti .table-body > ul > li ul li:first-child {
  width: 90%;
}

#utenti .table-header > ul.filters > li:first-child {
  width: 80%;
}

#utenti .table-body .droppable-container .badge-dropped > ul > li {
  width: 100%;
}

#utenti .table-body .droppable-container .badge-dropped li {
  width: auto;
  padding-right: 20px;
}

/* ADMIN USERS */
#adminusers .table-header > ul > li,
#adminusers .table-body li > ul > li {
  width: 35%;
}

#adminusers .table-header > ul > li:last-child,
#adminusers .table-body li > ul > li:last-child {
  width: 15%;
}

/* DATE PICKER */
#datetimepicker1 .input-group-addon {
  cursor: pointer;
}

#datetimepicker1 .bootstrap-datetimepicker-widget {
  box-shadow: 0 -6px 12px rgba(0, 0, 0, 0.175);
}

.bootstrap-datetimepicker-widget .glyphicon {
  cursor: pointer;
}

/* LISTA EVENTI */
.event-container {
  width: 100%;
  padding: 15px;
}

#aggiornamento-firmware .options .labels,
#edit-badge .options .labels {
  vertical-align: top;
  margin-top: 12px;
}

#event-list ul {
  margin: 0;
}

#event-list .table-header li,
#event-list .table-body li > ul > li {
  display: inline-block;
  width: 12%;
  padding: 0;
}

/* CHOSEN OVERWRITE */
.chosen-container-multi ul.chosen-choices {
  background-image: none;
  height: 34px;
  padding: 3px 7px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.chosen-container-multi.chosen-container-active ul.chosen-choices {
  border-color: #66afe9;
}

li.search-field,
li.search-field input {
  width: 100%;
  min-width: 400px;
}

#utenti .badge-droppable.dropping,
#profili .badge-droppable.dropping,
.droppable-container ul.dropping,
.terminali-profilo .droppable-container ul.dropping,
.eventstable tbody tr:hover,
.eventstable tbody tr:nth-child(odd):hover {
  background-color: #99abbc;
  color: #fff;
  transition: all ease-in-out 0.2s;
}

/* UPLOAD FILES */
.upload-files {
  width: 100%;
  min-height: 200px;
  border: 5px dashed #949494;
  margin-bottom: 30px;
  text-align: center;
  padding: 10%;
}

.upload-files.hover {
  background-color: #99abbc;
}

.upload-files.filedropped {
  padding: 0;
}

.upload-files.filedropped ul {
  margin: 0;
  padding: 0;
}

.upload-files.filedropped li {
  list-style-type: none;
  width: 100%;
  padding: 10px 20px;
  text-align: left;
  border-bottom: 1px solid #dedede;
  font-size: 1.1em;
  color: #334556;
}

.upload-files.filedropped li:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.04);
}

.upload-files.uploaded {
  border: none;
}

.droppable-container li:hover span.glyphicon-link,
li.badge-active:hover span.glyphicon-link {
  display: none;
}

.droppable-container li:hover span.glyphicon-minus,
li.badge-active:hover span.glyphicon-minus {
  display: block;
}

/* EVENTS TABLE */
.eventstable {
  width: 100%;
}

.eventstable td {
  padding: 8px 0;
}

.eventstable tbody tr td:first-child,
.eventstable thead tr:last-child th:first-child {
  padding-left: 10px;
}

.eventstable .filters .glyphicon-remove {
  position: absolute;
  top: 10px;
  right: 42px;
  z-index: 10;
}

#stop-real-time {
  display: none;
}

.eventstable thead tr:last-child div {
  display: none;
  position: fixed;
  top: 100px;
  padding: 10px 0;
  z-index: 20;
}

.fixed-header-bg {
  display: none;
  position: fixed;
  top: 100px;
  height: 40px;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 10;
  border-bottom: 1px solid #e4e4e4;
}

/* EVENTS TABLE FILTERS */
.eventstable thead tr.filters {
  background-color: #e4e4e4;
}

.eventstable thead tr.filters th {
  padding: 10px 10px 10px 0;
  vertical-align: top;
}

.eventstable tr.filters th:first-child {
  max-width: 0;
  min-width: 150px;
}

.eventstable thead tr.filters th:first-child {
  padding-left: 10px;
}

.eventstable li {
  display: block;
}

.eventstable li.search-field,
li.search-field input {
  min-width: inherit;
}

#scheduled_update .panel-body .table-header ul > li,
#scheduled_update .panel-body .table-body ul li li {
  width: 40%;
}

.schedule.options {
  margin-top: 10px;
}

/* JOBS HISTORY */
.table-striped.jobs-history .table-header ul > li,
.table-striped.jobs-history .table-body ul li li {
  width: 30%;
}

.job-done ul {
  background-color: rgba(132, 238, 124, 0.2);
}

.job-canceled ul {
  background-color: rgba(0, 0, 0, 0.1);
  color: #a2a2a2;
}

.job-canceled ul li {
  text-decoration: line-through;
}

.job-canceled ul li:last-child {
  text-decoration: none;
}

.login_campo {
  margin-bottom: 15px;
}

#todate {
  margin-top: 10px;
}

/* ADMIN */
.ipaddress {
  letter-spacing: 2px;
  text-align: right;
}

button#changeIPSubmit span.glyphicon-refresh.gly-spin {
  display: none;
}

div.username {
  position: relative;
}

.username .glyphicon-ok,
.username .glyphicon-remove {
  display: none;
  position: absolute;
  top: 5px;
  right: 0;
  color: #5cb85c;
}

.username.has-error .glyphicon-remove {
  display: block;
}

.username.has-success .glyphicon-ok {
  display: block;
}

.gly-spin {
  animation: spin 2s infinite linear;
}

#firmware_version {
  margin: 5px 15px;
}

.img-placeholder {
  width: 100%;
  height: 64px;
  border: 2px dashed #333;
  text-align: center;
  line-height: 64px;
  font-size: 2em;
  font-weight: bold;
  cursor: pointer;
}

.file-list {
  margin-top: 20px;
}

.file-list button.btn {
  float: right;
  clear: both;
  margin-bottom: 8px;
}

.file-list .progress {
  margin-top: 20%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }


  100% {
    transform: rotate(359deg);
  }
}

/* RESPONSIVE TABLE */
@media (max-width: 768px) {
  thead {
    display: none;
  }

  .fixed-header-bg {
    display: none !important;
  }

  tbody tr:nth-child(even) {
    background-color: transparent;
  }

  td:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.1);
  }

  td::before {
    content: attr(data-th) ": ";
    display: inline-block;
    text-align: right;
    font-weight: 900;
    margin-right: 10px;
    color: #000;
    width: 30%;
  }

  td {
    display: block;
    text-align: left;
    width: 100%;
  }

  td:first-child,
  td:first-child::before {
    width: 100%;
    text-align: center;
    color: #fff;
  }

  tr td:first-child {
    background: #334556;
    font-weight: bold;
    font-size: 1.1em;
    padding: 8px;
    color: #fff;
  }

  tr td:first-child strong span {
    color: #fff;
  }

  tr td:first-child::before {
    content: attr(data-th);
    font-weight: normal;
    display: block;
    margin-right: 0;
  }

  .eventstable tbody tr:hover,
  .eventstable tbody tr:nth-child(odd):hover {
    background-color: transparent;
    color: inherit;
  }

  .navbar #main-menu-collapse {
    margin-top: 0;
    background-color: #334556;
  }
}

/* utils */
.font-white {
  color: #fff;
}

.white-bg {
  background-color: #fff;
}

.badge.white-bg {
  background-color: #fff;
}

.padding-left-5 {
  padding-left: 5px;
}

.padding-right-5 {
  padding-right: 5px;
}
.padding-left-30 {
  padding-left: 30px;
}

.padding-right-30 {
  padding-right: 30px;
}

.custom-col {
  position: relative;
  float: left;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.line-height-3{
    line-height: 3;
}


.no-opacity{
    opacity: 1!important;
}

div[stand-alone-maps] {
    height: 100%!important;
}


.map-border-left{
    border-left: 1px solid #cbc1ac;
}

.margin-right-0{
    margin-right: 0!important;
}
.hicloud-grid .plant-number{
    text-align: center;
}
.hicloud-grid .img-icon {
    width: 64px;
    height: 64px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

@media (max-width: 1200px) {
    .hicloud-grid .btn-container {
        text-align: center;
    }
    .hicloud-grid .btn-container .pull-right {
        float: none !important;
    }
}
.row.row-grid  [class*="col-"] {
    margin-top: 20px;
}

.row.row-grid.icon-grid a  span{
    color:dimgray;
    font-size: 26px;
}

.dragover {
  border: 5px dashed blue;
}

.installation-buttons {
  height: 250px;
}


.sk-folding-cube .sk-cube:before {
  background-color: rgb(6, 73, 223) !important;
}

.color-gray {
  opacity: 1 !important;
  color: #999;
}

html, body,div.container-fluid,ui-view,[ui-view] {
  min-height: 100%;
  height: 100%;
}

.full-height{
  min-height: 100%!important;
  height: 100%!important;
}
.full-width{
  width :100%;
}


.margin-top-5 {
    margin-top:5px;
}
.margin-top-10 {
    margin-top:10px;
}
.margin-right-15{
    margin-right:15px;
}


.font-currier{
    font-family:Courier, monospace;;
}

.angleicon{
    color: #b3af9c;
}

.searchButton{
    background-color: #b3af9c;
}

.addPadding{
    padding-bottom:10px;
}


.hicloud-grid .caption {
    line-height: 30px;
    padding: 8px;
    background-color: rgba(255, 255, 255, 0.3);
    text-transform: uppercase;
}

.hicloud-grid .grid-header,.hicloud-grid  .grid-rows {
    padding: 8px;
    vertical-align:middle;
    text-align: left;
}
.hicloud-grid .grid-header{
    border-bottom: 2px solid #ddd;
    font-weight: bold;
    border-top:0;
    margin-bottom:1px;
}
.hicloud-grid *:nth-of-type(2n+1)  .grid-rows {
    background-color: #f9f9f9;
}
.hicloud-grid *:nth-of-type(2n) .grid-rows {
    background-color: rgba(250, 250, 250, 0.3);
}
.hicloud-grid .grid-rows .btn-toolbar button {
    margin-bottom: 2px;
}

/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

/* Small devices (tablets, 768px and up) */
@media (min-width:768px) {
    .pull-right-lg{float:inherit;}
    .pull-left-lg{float:inherit;}
    .pull-right-md{float:inherit;}
    .pull-left-md{float:inherit;}
    .pull-right-sm{
        float:right!important;
    }
    .pull-left-sm{
        float:left!important;
    }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .pull-right-lg{float:inherit;}
    .pull-left-lg{float:inherit;}
    .pull-right-md{
        float:right!important;
    }
    .pull-left-md{
        float:left!important;
    }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .pull-right-lg{
        float:right!important;
    }
    .pull-left-lg{
        float:left!important;
    }
}

.no-pad-left{
    padding-left: 0;
}
.no-pad-right{
    padding-right: 0;
}

.no-pad-left-right{
    padding-left: 0;
    padding-right: 0;
}


#navbar { display: none; }
#navbar_filsort { display: none; }
#item_number_selected { display: none; }
#btnFilter { display: none; }
#resp_text { display: none; }

  .pagination-view {
    display: table-footer-group;
    padding-left: 0;
    margin: 0;
    border-radius: 4px;
  }
  .pagination-view ul {
    display: contents;
    padding-left: 0;
    margin: 0;
    border-radius: 4px;
  }
  .label-view {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: normal;
  }
  .label-th {
    display: block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: normal;
  }
  .glyphicon-filter {
    color:black;
    background-color: #327a81;
    border-radius: 0%;
  }
  .lightback {
    background-color: #ebecf0;
    padding-bottom: 10px;
  }
  .header {
    background-color: #327a81;
    color: white;
    font-size: 1.5em;
    padding: 1rem;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: -1px;
    margin-top: 30px;
  }

  .header2 {
    background-color: #327a81;
    color: white;
    font-size: 1.5em;
    padding: 1rem;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
    /*margin-top: 30px;*/
  }

  .table-plants .footerV {
    background-color:#21262a;
    color: #aaadb2;
    font-size: 1.5em;
    padding: 1rem;
    text-align: center;
    text-transform: uppercase;
    /*margin-bottom: 10px;*/
    /*margin-top: 30px;*/
    border-bottom: 0px;
    border-radius: 10px 10px 10px 10px;
  }

  .table-plants {
    /*border: 1px solid #327a81;
    border-radius: 10px;
    box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.1);*/
    max-width: calc(100% - 2em);
    margin: 1em auto;
    /*overflow: hidden;*/
    width: 100%;
  }

  table {
    width: 100%;
  }
  .table-plants td,
  .table-plants th {
    color: #2b686e;
    padding: 10px;
  }
  .table-plants td {
    text-align: left;
    vertical-align: middle;
  }
  .table-plants td:last-child {
    font-size: 0.95em;
    line-height: 1.4;
    text-align: left;
  }
  .table-plants th {
    background-color: #daeff1;
    font-weight: 300;
  }
  .table-plants tr:nth-child(2n) {
    background-color: white;
  }
  .table-plants tr:nth-child(2n + 1) {
    background-color: #edf7f8;
  }

  @media screen and (max-width: 770px) {
    #leftCol { display: none; } /* Nasconde la barra laterale su schermi piccoli */
    #navbar { display: block; }
    #navbar_filsort { display: block; }
    #item_number_selected { display: block; }
    #plant_number_td { display: none; }
    #plant_number_th { display: none; }
    #btnFilter { display: contents; }
    #resp_text { display: contents; }

    .resp-table {
        display: block;
    }

    .resp-caption {
        display:flow-root;
    }

    table,
    tr,
    td {
      display: block;
    }
    td:not(:first-child) {
      clear: both;
      margin-left: 100px;
      padding: 4px 20px 4px 90px;
      position: relative;
      text-align: left;
    }
    td:not(:first-child):before {
      color: #91ced4;
      content: "";
      display: block;
      left: 0;
      position: absolute;
    }

    .header {
      /* background-color: transparent;*/
      background-color: #327a81;
      color: white;
      font-size: 2em;
      font-weight: 700;
      padding: 0;
      text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
    }

    .input-group .form-control {
        width: 80%;
        margin-bottom: 0;
    }

    .button-resp-sort {
        margin-top: 10px;
    }

    .tbody-resp{
        width: 100%;
        display: table;
    }

    td:first-child {
      background-color: #c8e7ea;
      border-bottom: 1px solid #91ced4;
      border-radius: 10px 10px 0 0;
      position: relative;
      top: 0;
      transform: translateY(0);
      width: 100%;
    }
    td:not(:first-child) {
      margin: 0;
      padding: 5px 1em;
      width: 100%;
    }
    td:not(:first-child):before {
      font-size: 0.8em;
      padding-top: 0.3em;
      position: relative;
    }
    td:last-child {
      padding-bottom: 1rem !important;
    }

    tr {
      background-color: white !important;
      border: 1px solid #6cbec6;
      border-radius: 10px;
      box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
      margin: 0.5rem 0;
      padding: 0;
    }

    input[type=text] {
        /*width: 50%;*/
        padding: 12px 20px;
        margin: 8px 0;
        box-sizing: border-box;
      }

    .table-plants {
      border: none;
      box-shadow: none;
      overflow: visible;
    }

    .table-plants tr:first-child {
      display: none;
    }

    .btn-calendar {
        color: #333;
        background-color: #fff;
        border-color: #ccc;
        z-index: 2;
        margin-left: 0;
    }
  }



@media (min-width: 992px) {
    .modal-xlg > .modal-lg {
        width: 900px;
    }
}
@media (min-width: 1200px) {
    .modal-xlg > .modal-lg {
        width: 1100px;
    }
}
@media (min-width: 1360px) {
    .modal-xlg > .modal-lg {
        width: 1200px;
    }
}
@media (min-width: 1440px) {
    .modal-xlg > .modal-lg {
        width: 1300px;
    }
}
@media (min-width: 1600px) {
    .modal-xlg > .modal-lg {
        width: 1500px;
    }
}
@media (min-width: 1920px) {
    .modal-xlg > .modal-lg {
        width: 1800px;
    }
}
@media (min-width: 2048px) {
    .modal-xlg > .modal-lg {
        width: 1900px;
    }
}
@media (min-width: 2560px) {
    .modal-xlg > .modal-lg {
        width: 2400px;
    }
}
canvas {
    border:none !important;
}



@media (min-width: 992px) {
    .modal-xlg > .modal-lg {
        width: 900px;
    }
}
@media (min-width: 1200px) {
    .modal-xlg > .modal-lg {
        width: 1100px;
    }
}
@media (min-width: 1360px) {
    .modal-xlg > .modal-lg {
        width: 1200px;
    }
}
@media (min-width: 1440px) {
    .modal-xlg > .modal-lg {
        width: 1300px;
    }
}
@media (min-width: 1600px) {
    .modal-xlg > .modal-lg {
        width: 1500px;
    }
}
@media (min-width: 1920px) {
    .modal-xlg > .modal-lg {
        width: 1800px;
    }
}
@media (min-width: 2048px) {
    .modal-xlg > .modal-lg {
        width: 1900px;
    }
}
@media (min-width: 2560px) {
    .modal-xlg > .modal-lg {
        width: 2400px;
    }
}
canvas {
    border:none !important;
}


.material-switch > input[type="checkbox"] {
  display: none;
}

.material-switch > label {
  cursor: pointer;
  height: 0;
  position: relative;
  width: 40px;
}

.material-switch > label::before {
  background: rgb(0, 0, 0);
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  content: '';
  height: 16px;
  margin-top: -8px;
  position: absolute;
  opacity: 0.3;
  transition: all 0.4s ease-in-out;
  width: 40px;
}

.material-switch > label::after {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  content: '';
  height: 24px;
  left: -4px;
  margin-top: -8px;
  position: absolute;
  top: -4px;
  transition: all 0.3s ease-in-out;
  width: 24px;
}

.material-switch > input[type="checkbox"]:checked + label::before {
  background: inherit;
  opacity: 0.5;
}

.material-switch > input[type="checkbox"]:checked + label::after {
  background: inherit;
  left: 20px;
}

